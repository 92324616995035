<template>
  <div class="flex items-center w-full cell-renderer-select-settings-container">
      <!-- append-to-body very important https://vue-select.org/api/props.html#appendtobody -->
      <v-select v-if="hasSingleOptions" :value="selectedSettings" :clearable="false" append-to-body  @input="setSelectedSetting" :options="settingsOptions" />
      <v-select v-if="hasMultipleOptions" :value="selectedSettings" multiple append-to-body  @input="setSelectedSetting" :options="settingsOptions" />
      <vs-input v-if="isText" :value="inputValue" @change="setSetting" class="w-full" />
      <!-- <vs-button @click="onSave" icon-pack="feather" icon="icon-save" class="ml-2"></vs-button> -->
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  name: 'CellRendererIntegrationSettingsValue',
  components: {
    vSelect
  },
  data () {
    return {
      selectedSettings: null,
      inputValue: ''
    }
  },
  computed: {
    settingsOptions () {
      return this.params.data.settingOptions
    },
    isText () {
      return !this.params.data.settingOptions || this.params.data.settingOptions.length == 0
    },
    hasSingleOptions () {
      return this.params.data.settingOptions && 
        this.params.data.settingOptions.length > 0
        && !this.params.data.settingOptionsMultiple
    },
    hasMultipleOptions () {
      return this.params.data.settingOptions && 
        this.params.data.settingOptions.length > 0
        && this.params.data.settingOptionsMultiple
    },

  },
  mounted () {
    // Single
    if(this.isText) {
      this.inputValue = this.params.data.settingValue
    } else if (this.hasSingleOptions) {
      this.selectedSettings = this.params.data.settingValue
    } else if (this.hasMultipleOptions) {
      this.selectedSettings = this.params.data.settingValue ? this.params.data.settingValue.split(",") : null
    }
    
  },
  methods: {
    setSelectedSetting (value) {
      if (this.hasSingleOptions) {
        this.params.data.settingValue = value
      } else if (this.hasMultipleOptions) {
        this.params.data.settingValue = value.length > 0 ? value.join(",") : ""
      }
      this.selectedSettings = value
      // only mark as dirty if form is update
      this.params.data.isDirty = !this.params.data.isNewSettings
      this.params.context.componentParent.onDirty()
    },
    setSetting (e) {
      this.params.data.settingValue = e.target.value
      this.inputValue = e.target.value
      // only mark as dirty if form is update
      this.params.data.isDirty = !this.params.data.isNewSettings
      this.params.context.componentParent.onDirty()
    },
    onSave () {
      if(this.params.data.settingValue) {
        this.params.context.componentParent.onSettingChanged(this.params.data.settingName)  
        //TODO: make onSettingChanged return a promise
        this.params.data.isDirty = false
      } else {
        this.params.context.componentParent.onSettingEmptyChanged(this.params.data.settingName)  
      }
    }
  }
}
</script>

<style>
/* Make sure the checkbox centers vertically in the row*/
.cell-renderer-select-settings-container {
  height: 100%;
}
/* Make drop-down options visible and fill up the entire cell */
.cell-renderer-select-settings-container .v-select {
  width: 100% !important;
}

/* Succeding styles fixes wrapping for long text- https://github.com/sagalbot/vue-select/issues/754 */
/* .cell-renderer-select-settings-container .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 41px);
}

.cell-renderer-select-settings-container .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

.cell-renderer-select-settings-container .vs__search {
  position: absolute;
  height: 100%;
}

.cell-renderer-select-settings-container .vs--open .vs__search {
  position: static;
}

.cell-renderer-select-settings-container .vs__dropdown-option {
  white-space: normal;
} */

</style>